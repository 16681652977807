import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=4d045945&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=4d045945&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d045945",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/Users/tdrdimov/gogoproj/fitapp - current GIT/fitnhealthy/src/components/Footer.vue').default})
