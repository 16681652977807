import * as firebase from 'firebase/app'

const config = {"apiKey":"AIzaSyDSoTITJUUoHtJ4yfMgLiPhvTXbx7CzSag","authDomain":"inspireforhealthy-13a40.firebaseapp.com","databaseURL":"https:\u002F\u002Finspireforhealthy-13a40.firebaseio.com","projectId":"inspireforhealthy-13a40","storageBucket":"inspireforhealthy-13a40.appspot.com","messagingSenderId":"856303839598","appId":"1:856303839598:web:2447074e98b14c1c","measurementId":"G-SXXGWQETWM"}

export default async ({ res }, inject) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
  const session = firebase.apps[0]

  /** --------------------------------------------------------------------------------------------- **/
  /** -------------------------------------- FIREBASE AUTH ---------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/auth')

  const fireAuth = session.auth()
  const fireAuthObj = firebase.auth
  inject('fireAuth', fireAuth)
  inject('fireAuthObj', fireAuthObj)

  /** --------------------------------------------------------------------------------------------- **/
  /** ------------------------------------- FIREBASE FIRESTORE ------------------------------------ **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/firestore')

  const fireStore = session.firestore()
  const fireStoreObj = firebase.firestore
  inject('fireStore', fireStore)
  inject('fireStoreObj', fireStoreObj)

  /** --------------------------------------------------------------------------------------------- **/
  /** -------------------------------------- FIREBASE STORAGE ------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/storage')

  const fireStorage = session.storage()
  const fireStorageObj = firebase.storage
  inject('fireStorage', fireStorage)
  inject('fireStorageObj', fireStorageObj)

  /** --------------------------------------------------------------------------------------------- **/
  /** ------------------------------------ FIREBASE FUNCTIONS ------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/functions')

  const fireFunc = session.functions()
  const fireFuncObj = firebase.functions

  inject('fireFunc', fireFunc)
  inject('fireFuncObj', fireFuncObj)

  /** --------------------------------------------------------------------------------------------- **/
  /** ------------------------------------ FIREBASE MESSAGING ------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Messaging can only be initiated on client side
  if (process.client) {
    await import('firebase/messaging')

    if (firebase.messaging.isSupported()) {
      const fireMess = session.messaging()
      const fireMessObj = firebase.messaging

        fireMess.usePublicVapidKey("BBwwgk7C8Ww8k1pvJjc1CQsVR0clM2KbChnSz1qee89dN2yLFOGZ0FuThLaafGbVeZQC0ALzFUpxcgn-cIij050")

      inject('fireMess', fireMess)
      inject('fireMessObj', fireMessObj)
    }
  }

  /** --------------------------------------------------------------------------------------------- **/
  /** ----------------------------------- FIREBASE PERFORMANCE ------------------------------------ **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Performance can only be initiated on client side
  if(process.client) {
    await import('firebase/performance')

    const firePerf = session.performance()
    const firePerfObj = firebase.performance
    inject('firePerf', firePerf)
    inject('firePerfObj', firePerfObj)
  }

  /** --------------------------------------------------------------------------------------------- **/
  /** ----------------------------------- FIREBASE ANALYTICS -------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Analytics can only be initiated on the client side
  if (process.client) {
    await import('firebase/analytics')

    const fireAnalytics = session.analytics()
    const fireAnalyticsObj = firebase.analytics
    inject('fireAnalytics', fireAnalytics)
    inject('fireAnalyticsObj', fireAnalyticsObj)
  }
}
