
export default {
  props: {
    page: String
  },
  data() {
    return {
      clientName: null,
      userAvatar: null,
      uploadProgress: null,
      isLoading: false,
      clientId: localStorage.getItem('clientId')
    }
  },
  methods: {
    async onFileChanged(event) {
      this.isLoading = true
      const [file] = event.target.files
      this.userAvatar = file
      const imgPath = `avatars/${this.clientId ? this.clientId : this.user}`
      if (this.userAvatar != null) {
        this.$fireStorage
          .ref()
          .child(imgPath)
          .put(this.userAvatar)
          .then(() => {
            this.$fireStorage
              .ref()
              .child(imgPath)
              .getDownloadURL()
              .then((url) => {
                this.$store.commit('SET_USER_AVATAR', url)
                this.isLoading = false
                this.getAvatarUrl()
              })
              .catch((err) => {
                console.log('get avatar: ', err)
                this.isLoading = false
              })
          })
      }
    },

    uploadAvatar() {
      this.$refs.imgField.click()
    },

    async getAvatarUrl() {
      this.isLoading = true
      this.$store.commit('SET_USER_AVATAR', null)
      const userId = this.clientId ? this.clientId : this.user
      const imgPath = `avatars/${userId}`
      const imgResized = `avatars/resized/${userId}_200x200`
      try {
        const clientInfo = await this.$fireStore.collection('clients-information').doc(userId).get()

        if (clientInfo.exists) {
          this.clientName = `${clientInfo.data().first_name} ${
            clientInfo.data().last_name ? clientInfo.data().last_name : ''
          }`
        } else {
          this.clientName = null
        }
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
      }

      const original = await this.$fireStorage.ref().child(imgPath).getDownloadURL()
      this.$store.commit('SET_USER_AVATAR', original)

      try {
        const resized = await this.$fireStorage.ref().child(imgResized).getDownloadURL()
        if (resized) {
          this.$store.commit('SET_USER_AVATAR', resized)
        }
      } catch (err) {
        if (err.code !== 'storage/object-not-found') {
          throw err
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    admin() {
      return this.$store.state.admin
    },
    userAvatarUrl() {
      return this.$store.state.userAvatar
    }
  },
  watch: {
    clientId: {
      handler() {
        // admin has no avatar functionality
        this.getAvatarUrl()
      },
      deep: true,
      immediate: true
    }
  }
}
