
import Cookie from 'js-cookie'

export default {
  name: 'serverRender',
  data() {
    return {
      isLoading: true
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
    },
    closeMenu() {
      this.$store.commit('setMenuState', false)
    }
  },
  beforeCreate() {
    this.isLoading = true
  },
  async beforeMount() {
    if (!Cookie.get('access_token')) {
      this.logout()
    }
  },
  mounted() {
    this.$fb.enable()
    this.isLoading = false
    /* eslint-disable-next-line */
    if (process.browser) {
      // REMOVE VUEX PERSIST
      window.addEventListener('beforeunload', () => {
        localStorage.removeItem('vuex')
      })
    }
  },
  computed: {
    profileTab() {
      return this.$store.state.profileTab
    }
  }
}
