import Vue from 'vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 8000,
    showProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true
  }
}

Vue.use(Snotify, options)
