import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6ccc670e = () => interopDefault(import('../../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _acefe80e = () => interopDefault(import('../../src/pages/book-session/index.vue' /* webpackChunkName: "pages/book-session/index" */))
const _f4db02e6 = () => interopDefault(import('../../src/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _37fb96fa = () => interopDefault(import('../../src/pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _4f4840ae = () => interopDefault(import('../../src/pages/fitness-program-manager/index.vue' /* webpackChunkName: "pages/fitness-program-manager/index" */))
const _9a0d6b56 = () => interopDefault(import('../../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _bca95bc4 = () => interopDefault(import('../../src/pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _2e324476 = () => interopDefault(import('../../src/pages/programs/index.vue' /* webpackChunkName: "pages/programs/index" */))
const _1e823267 = () => interopDefault(import('../../src/pages/register.vue' /* webpackChunkName: "pages/register" */))
const _322118fc = () => interopDefault(import('../../src/pages/start-today.vue' /* webpackChunkName: "pages/start-today" */))
const _6e584cf5 = () => interopDefault(import('../../src/pages/terms-conditions-in-person/index.vue' /* webpackChunkName: "pages/terms-conditions-in-person/index" */))
const _9633f49e = () => interopDefault(import('../../src/pages/terms-conditions-online/index.vue' /* webpackChunkName: "pages/terms-conditions-online/index" */))
const _5315af03 = () => interopDefault(import('../../src/pages/dashboard/admin/index.vue' /* webpackChunkName: "pages/dashboard/admin/index" */))
const _2d5cb184 = () => interopDefault(import('../../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1be7405e = () => interopDefault(import('../../src/pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6ccc670e,
    name: "about"
  }, {
    path: "/book-session",
    component: _acefe80e,
    name: "book-session"
  }, {
    path: "/checkout",
    component: _f4db02e6,
    name: "checkout"
  }, {
    path: "/dashboard",
    component: _37fb96fa,
    name: "dashboard"
  }, {
    path: "/fitness-program-manager",
    component: _4f4840ae,
    name: "fitness-program-manager"
  }, {
    path: "/login",
    component: _9a0d6b56,
    name: "login"
  }, {
    path: "/privacy-policy",
    component: _bca95bc4,
    name: "privacy-policy"
  }, {
    path: "/programs",
    component: _2e324476,
    name: "programs"
  }, {
    path: "/register",
    component: _1e823267,
    name: "register"
  }, {
    path: "/start-today",
    component: _322118fc,
    name: "start-today"
  }, {
    path: "/terms-conditions-in-person",
    component: _6e584cf5,
    name: "terms-conditions-in-person"
  }, {
    path: "/terms-conditions-online",
    component: _9633f49e,
    name: "terms-conditions-online"
  }, {
    path: "/dashboard/admin",
    component: _5315af03,
    name: "dashboard-admin"
  }, {
    path: "/",
    component: _2d5cb184,
    name: "index"
  }, {
    path: "/programs/:program",
    component: _1be7405e,
    name: "programs-program"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
