import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=6bccf0f7"
import script from "./Logo.vue?vue&type=script&lang=js"
export * from "./Logo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoHeart: require('/Users/tdrdimov/gogoproj/fitapp - current GIT/fitnhealthy/src/components/LogoHeart.vue').default})
