
export default {
  name: 'main-footer',
  watchQuery: ['page'],
  data() {
    return {
      emaildata: {
        email: ''
      },
      scrollTopBtn: false,
      sent: false
    }
  },
  methods: {
    hideFooter() {
      const pageName = this.$router.history.current.name
      const hideFooterPages = ['book-session', 'dashboard', 'dashboard-admin']
      if (hideFooterPages.includes(pageName)) {
        return false
      }
      return true
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    ifScrollToBottom() {
      if (window.scrollY > 1500) {
        this.scrollTopBtn = true
      } else {
        this.scrollTopBtn = false
      }
    },
    subscribe() {
      /* eslint-disable-next-line */
      const emailVal = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emaildata.email)
      if (emailVal) {
        this.$fireStore
          .collection('subscribers')
          .add({ email: this.emaildata.email })
          .then(() => {
            this.$fireAnalytics.logEvent('email_list_subscribe', { email: this.emaildata.email })
            this.$snotify.success(
              'Thank you for subscribing, we will keep you updated!',
              'Subscribed!'
            )
            this.emaildata.email = ''
          })
      } else {
        this.$snotify.error('Invalid email!', 'Ops!')
      }
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.ifScrollToBottom)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.ifScrollToBottom)
  },
  watch: {
    page() {
      this.hideFooter()
    }
  },
  computed: {
    signupAboutSize() {
      return {
        'c-25': this.signupBoolean,
        'c-4': !this.signupBoolean
      }
    },
    user() {
      return this.$store.state.user
    }
  }
}
