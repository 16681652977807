
export default {
  name: 'SlideOut',
  props: {
    user: String,
    admin: Boolean
  },
  data() {
    return {
      isOpen: false,
      isDashboard: false,
      show: 0,
      tabs: [
        {
          title: 'News Feed',
          component: 'Feed',
          adminOnly: false
        },
        {
          title: 'Fitness Program',
          component: 'FitnessProgram',
          adminOnly: false
        },
        {
          title: 'Workout Log',
          component: 'WorkoutLog',
          adminOnly: false
        },
        {
          title: 'Check In',
          component: 'CheckIn',
          adminOnly: false
        },
        {
          title: 'Nutrition Program',
          component: 'NutritionsProgram',
          adminOnly: false
        },
        {
          title: 'Before / After Photos',
          component: 'BeforeAfter',
          adminOnly: false
        },
        {
          title: 'Measurements',
          component: 'Measurements',
          adminOnly: true
        },
        {
          title: 'Level of fitness',
          component: 'LevelOfFitness',
          adminOnly: true
        },
        {
          title: 'Trainer Notes',
          component: 'TrainerNotes',
          adminOnly: true
        }
      ]
    }
  },
  mounted() {
    this.manageUserBtn()
  },
  methods: {
    navigate(ind, tab) {
      if (tab.title === 'Community News Feed' && !this.admin && !this.hasActiveService) {
        this.showSubscrMessage(tab.title)
        return false
      }
      this.show = ind
      this.$store.commit('SET_PROFILETAB', this.show)
      localStorage.profileTab = this.show
      this.$store.commit('toggleMenuState')
      if (!this.$route.path.match('/dashboard')) {
        this.$router.push('/dashboard')
      }
      return true
    },
    goTo(path) {
      return this.$router.push(path)
    },
    manageUserBtn() {
      if (this.user && !this.admin) {
        if (this.$route.path.includes('/dashboard')) {
          this.isDashboard = true
        } else {
          this.isDashboard = false
        }
      } else if (this.$route.path.includes('/dashboard/admin')) {
        this.isDashboard = true
      } else {
        this.isDashboard = false
      }
    },
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
    toggle() {
      this.$store.commit('toggleMenuState')
      if (this.isOpen) {
        this.close()
      } else {
        this.open()
      }
    },
    showSubscrMessage(to) {
      if (!this.admin && (!this.hasActiveService || !this.userInfo.stripeSubscriptionId)) {
        this.$snotify.warning(
          `You must have a active ${
            to === 'calendar' ? 'subscription' : 'fitness program'
          } to have access to the ${to}.`
        )
        return false
      }
      return true
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    hasActiveService() {
      const hasActivePrograms = this.clientPurchasedPrograms.every((pr) => pr.isExpired)
      if (!hasActivePrograms || this.userInfo.stripeSubscriptionId) {
        return true
      }
      return false
    },
    clientPurchasedPrograms() {
      return this.$store.state.clientPurchasedPrograms
    },
    tabState() {
      return this.$store.state.profileTab
    },
    isProfilePage() {
      return this.$store.state.isProfilePage
    }
  },
  watch: {
    tabState(nV) {
      this.show = nV
    },
    $route() {
      this.manageUserBtn()
    }
  }
}
