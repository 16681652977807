
import Cookie from 'js-cookie'

export default {
  name: 'dashboard-layout',
  data() {
    return {
      loggedOut: false,
      clientId: null
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
    },
    closeMenu() {
      this.$store.commit('setMenuState', false)
    },
    checkCookie(e) {
      // if cookie is expired or doesn't exists, logout user and redirect to login
      if (!Cookie.get('access_token') && !this.loggedOut) {
        e.preventDefault()
        this.logout()
        this.loggedOut = true
        // when redirect to any server rendered page (login page) and then login back
        // this.loggedOut is refreshed which
        // means it is set to false since profile is client rendered
        if (document.getElementById('default_template')) {
          this.$router.push('/login')
        }
      }
    },
    addDomEventObserver() {
      const observer = new MutationObserver((list) => {
        const evt = new CustomEvent('dom-changed', { detail: list })
        document.body.dispatchEvent(evt)
      })
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true
      })

      document.body.addEventListener('dom-changed', (e) => this.checkCookie(e))
    }
  },
  async beforeMount() {
    if (!this.loggedOut) {
      this.addDomEventObserver()
    }
  },
  async mounted() {
    this.clientId = localStorage.getItem('clientId')
    this.$fb.enable()
    // REMOVE VUEX PERSIST
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('vuex')
    })

    if (!this.clientPrograms || !this.clientPrograms.length) {
      await this.$store.dispatch('getUserPrograms', this.clientId)
    }
  },
  computed: {
    profileTab() {
      return this.$store.state.profileTab
    },
    tabs() {
      return this.$store.state.tabs
    },
    adminId() {
      return this.$store.state.adminId
    },
    clientPrograms() {
      return this.$store.state.clientPrograms
    }
  }
}
