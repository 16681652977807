
export default {
  data: () => ({
    menu: false,
    unseenNotifications: [],
    notifications: [],
    ntfTime: null
  }),
  mounted() {
    if (this.user) {
      this.getNotifications()
    }
  },
  methods: {
    handleNtf(notification) {
      if (notification.type !== 'profile_update') {
        this.$store.commit('SET_PROFILETAB', 0)
        localStorage.setItem('profileTab', 0)
        // scroll to post
        setTimeout(() => {
          const postCard = document.getElementById(`${notification.postId}`)
          if (postCard) {
            postCard.scrollIntoView()
          }
          if (notification.type === 'new_comment') {
            const commentsBtn = postCard.getElementsByClassName('post_comments')
            const commentsBtnNode = commentsBtn[0]
            commentsBtnNode.click()
          }
        }, 500)
      } else {
        this.$store.commit('SET_PROFILETAB', notification.activeTab)
        localStorage.setItem('profileTab', notification.activeTab)
      }
      this.menu = false
      this.unseenNotifications = this.unseenNotifications.filter(
        (ntf) => ntf.notificationId !== notification.notificationId
      )
      this.notifications = this.notifications.map((ntf) => {
        if (ntf.notificationId === notification.notificationId) {
          ntf.seen = true
        }
        return ntf
      })
      this.markSeen(notification)
      this.$router.push('/dashboard')
    },
    deleteNtf(notification) {
      this.markSeen(notification)
      this.unseenNotifications = this.unseenNotifications.filter(
        (ntf) => ntf.notificationId !== notification.notificationId
      )
      this.notifications = this.notifications.filter(
        (ntf) => ntf.notificationId !== notification.notificationId
      )
    },
    markSeen(notification) {
      const ntfRef = this.$fireStore.collection('notifications').doc(notification.notificationId)
      ntfRef
        .update({
          unseenBy: this.$fireStoreObj.FieldValue.arrayRemove(this.user)
        })
        .catch((error) => {
          console.error('Error updating notification: ', error)
        })
    },
    async getNotifications() {
      if (!this.user || this.admin) return
      const ntfRef = this.$fireStore.collection('notifications')
      const ntfctns = await ntfRef.orderBy('timestamp', 'desc').limit(10).get()

      ntfctns.forEach(async (doc) => {
        const data = doc.data()
        data.img = data.img ? data.img : await this.getUserAvatar(data.authorId)
        if (data.unseenBy.includes(this.user)) {
          this.notifications.push({ notificationId: doc.id, ...data })
        }
      })
      this.unseenNotifications = this.notifications
    },
    timeSince(date) {
      const timestamp = new Date(date * 1000)
      const seconds = Math.floor((new Date() - timestamp) / 1000)
      let interval = seconds / 31536000
      if (interval > 1) {
        this.ntfTime = `${Math.floor(interval)}y ago`
        return `${Math.floor(interval)}y ago`
      }
      interval = seconds / 2592000
      if (interval > 1) {
        this.ntfTime = `${Math.floor(interval)}month ago`
        return `${Math.floor(interval)}month ago`
      }
      interval = seconds / 86400
      if (interval > 1) {
        this.ntfTime = `${Math.floor(interval)}d ago`
        return `${Math.floor(interval)}d ago`
      }
      interval = seconds / 3600
      if (interval > 1) {
        this.ntfTime = `${Math.floor(interval)}h ago`
        return `${Math.floor(interval)}h ago`
      }
      interval = seconds / 60
      if (interval > 1) {
        this.ntfTime = `${Math.floor(interval)}m ago`
        return `${Math.floor(interval)}m ago`
      }
      this.ntfTime = `${Math.floor(seconds)}s ago`
      return `${Math.floor(seconds)}s ago`
    },
    async getUserAvatar(authorId) {
      const originalPath = `avatars/${authorId}`
      const resizedPath = `avatars/resized/${authorId}_200x200`

      try {
        // Attempt to get the download URL for the resized avatar
        const resizedURL = await this.$fireStorage.ref().child(resizedPath).getDownloadURL()
        return resizedURL
      } catch (error) {
        // If getting the resized avatar fails, attempt to get the original avatar
        try {
          const originalURL = await this.$fireStorage.ref().child(originalPath).getDownloadURL()
          return originalURL
        } catch (innerError) {
          // If both attempts fail, return a default avatar URL
          return '/images/user.svg'
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    admin() {
      return this.$store.state.admin
    },
    notificationsSortedByTimestamp() {
      const sortedArr = [...this.notifications]
      return sortedArr.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
    }
  },
  watch: {
    menu(nv) {
      if (nv && !this.notifications.length) {
        this.$snotify.warning('Nothing to see here — yet')
      }
    }
  }
}
